const { Swiper, Navigation, Pagination, Autoplay, Lazy } = require("swiper");
Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

import PhotoSwipeLightbox from "photoswipe/lightbox";

const lightbox = new PhotoSwipeLightbox({
  gallery: ".lightbox",
  children: "a",
  loop: false,
  pswpModule: () => import("photoswipe"),
});
lightbox.init();

const lightboxGallery = new PhotoSwipeLightbox({
  gallery: "#cnt-gallery",
  children: "a",
  loop: false,
  pswpModule: () => import("photoswipe"),
});
lightboxGallery.init();

// Swiper
document.addEventListener("DOMContentLoaded", () => {
  // main slider
  var slider = new Swiper(".slider", {
    loop: true,
    watchSlidesProgress: true,
    centeredSlides: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
    },
    speed: 600,
  });

  // testimonials
  var slider = new Swiper(".testimonials", {
    loop: true,
    watchSlidesProgress: true,
    spaceBetween: 30,
    breakpoints: {
      860: {
        slidesPerView: 2,
      },
      1240: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next-ts",
      prevEl: ".swiper-button-prev-ts",
    },
    autoplay: {
      delay: 5000,
    },
    speed: 600,
  });
});

// document animation transition
// document.addEventListener("DOMContentLoaded", () => {
//   document.body.className = "loading-visible";
// });

// Create an intersection observer with default options, that
// triggers a class on/off depending on an element’s visibility in the viewport
const animationObserver = new IntersectionObserver(
  (entries, observer) => {
    for (const entry of entries) {
      // console.log(entry.target)
      // const animation = entry.target.dataset.animate;

      // if( typeof animation !== 'undefined' )
      // console.log(animation)

      entry.isIntersecting
        ? entry.target.classList.remove("animate-none")
        : entry.target.classList.add("animate-none");
    }
  },
  { rootMargin: "10px", threshold: 0.5 }
);

// Use that IntersectionObserver to observe the visibility of some elements
for (const element of document.querySelectorAll("[data-animate]")) {
  animationObserver.observe(element);
}
